<template>
    <div v-if="voterInfo" class="p-relative">

        <!-- Тулбар -->
        <div class="top-btns-bar">
            <div class="transparent-buttons">
                <v-btn small tile text @click="Export">
                    <v-icon left>far fa-file-chart-line</v-icon>
                    {{ $t("Экспорт_в_Excel") }}
                </v-btn>
            </div>
        </div>

        <!--Форма-->
        <v-form class="wrapperForm" ref="form" lazy-validation>
            <v-row dense>
                <v-col cols="12" sm="12" md="12" class="section-wrapper">
                    <v-card flat>
                        <v-card-text>
                            <v-row class="two-columns-field-in-one-box" no-gutters>
                                <v-col cols="12" sm="12" md="6">
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("ИИН")}}</label>
                                        </v-col>
                                        <v-col  cols="12" sm="12" md="8">
                                            <div class="onlyReadData">{{ voterInfo.iin }}</div>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Фамилия")}}</label>
                                        </v-col>
                                        <v-col  cols="12" sm="12" md="8">
                                            <div class="onlyReadData">{{ voterInfo.surname }}</div>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Имя")}}</label>
                                        </v-col>
                                        <v-col  cols="12" sm="12" md="8">
                                            <div class="onlyReadData">{{ voterInfo.name }}</div> 
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Отчество")}}</label>
                                        </v-col>
                                        <v-col  cols="12" sm="12" md="8">
                                            <div class="onlyReadData">{{ voterInfo.patronymic }}</div>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Дата_рождения")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="onlyReadData">{{ voterInfo.birthDate | formattedDate }}</div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">

                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Пол")}}</label>
                                        </v-col>
                                        <v-col  cols="12" sm="12" md="8">
                                            <div class="onlyReadData">{{ getLocalizedName(voterInfo.gender) }}</div>
                                        </v-col>
                                    </v-row>
                                    
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Национальность")}}</label>
                                        </v-col>
                                        <v-col  cols="12" sm="12" md="8">
                                            <div class="onlyReadData">{{ getLocalizedName(voterInfo.nationality) }}</div>
                                        </v-col>
                                    </v-row>

                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Гражданство")}}</label>
                                        </v-col>
                                        <v-col  cols="12" sm="12" md="8">
                                            <div class="onlyReadData">{{ getLocalizedName(voterInfo.citizenship) }}</div>
                                        </v-col>
                                    </v-row>

                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Жизненный_статус")}}</label>
                                        </v-col>
                                        <v-col  cols="12" sm="12" md="8">
                                            <div class="onlyReadData">{{ getLocalizedName(voterInfo.lifeStatus) }}</div>
                                        </v-col>
                                    </v-row>

                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Дата_актуализации")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div 
                                                class="onlyReadData" 
                                                :set="date = $system.dateFormat($_.get(voterHistory, 'fromDate'), 'DD.MM.yyyy')"
                                            >{{ date ? date : $t('Нет_данных') }}</div>
                                        </v-col>
                                    </v-row>

                                </v-col>
                            </v-row>
                            <v-row class="one-column-field-in-one-box" no-gutters>
                                <v-col cols="12" sm="12" md="2">
                                    <label class="f-label">{{$t("Участок")}}</label>
                                </v-col>
                                <v-col  cols="12" sm="12" md="10">
                                    <div class="elipsis-text-wrap onlyReadData">
                                        <v-chip
                                            v-if="electionPart"
                                            class="table-label"
                                            small
                                            label
                                            dark
                                            color="indigo"
                                            @click.stop="$router.push({ name: 'ElectionPart', params: { id: electionPart.id } })"
                                        >
                                            <v-icon>
                                                far fa-person-booth
                                            </v-icon>
                                            <div>{{ electionPart.number }}</div>
                                        </v-chip>
                                        <div class="etw-text-container">
                                            <div class="etw-text" v-tooltip.top-center="getLocalizedName(electionPart, false)">
                                                {{ getLocalizedName(electionPart, false) }}
                                            </div>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        

            <v-tabs
                v-model="tab"
                background-color="transparent"
                slider-size="3"
                class="tab-selector-panel tab-after-section-wrapper"
                mobile-breakpoint="0"
            >
                <v-tabs-slider color="#E43958"></v-tabs-slider>

                <v-tab>
                    {{ $t("Адресные_сведения") }}
                </v-tab>
                <v-tab>
                    {{ $t("Документы") }}
                </v-tab>
                <v-tab>
                    {{ $t("Дополнительная_информация") }}
                </v-tab>
                <v-tab
                    @click="onVoterMovementsClicked"
                >
                    {{ $t("История_движения") }}
                </v-tab>
                <v-tab v-if="showJsonDataTab">
                    {{ $t("JSON") }}
                </v-tab>
            </v-tabs>

            <v-tabs-items
                v-model="tab"
                class="tab-content-panel"
            >
                <v-tab-item>
                    <v-row dense>
                        <!--Обертка для секции-->
                        <v-col cols="12" sm="12" md="12" class="section-wrapper">
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{$t("Адрес_регистрации")}}</div>

                                    <v-row class="one-column-field-in-one-box" no-gutters>
                                        <v-col cols="12" sm="12" md="2">
                                            <label class="f-label">{{ $t("Адрес_в_ГБД_ФЛ") }}</label>
                                        </v-col>
                                        <v-col  cols="12" sm="12" md="10">
                                            <div class="onlyReadData">{{ getLocalizedAddress(voterInfo.regAddress) }} ({{ $t('РКА') }}: {{ voterInfo.regAddress && voterInfo.regAddress.arCode ? voterInfo.regAddress.arCode : $t('Нет_данных') }})</div>
                                        </v-col>
                                    </v-row>

                                    <v-row class="one-column-field-in-one-box" no-gutters>
                                        <v-col cols="12" sm="12" md="2">
                                            <label class="f-label">{{ $t("Адрес_в_Адресном_регистре") }}</label>
                                        </v-col>
                                        <v-col  cols="12" sm="12" md="10">
                                            <div class="onlyReadData">
                                                <template v-if="addressRegistryPrimaryRka">{{ addressRegistryAddress ? addressRegistryAddress : $t('Нет_данных') }} ({{ $t('РКА') }}: {{ addressRegistryPrimaryRka }})</template>
                                                <template v-else>{{ $t('РКА_объекта_отсутствует_или_недействителен_в_Адресном_регистре') }}</template>
                                            </div>
                                        </v-col>
                                    </v-row>

                                    <v-row class="two-columns-field-in-one-box" no-gutters>
                                        <v-col cols="12" sm="12" md="6">
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{$t("Дата_регистрации")}}</label>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="8">
                                                    <div class="onlyReadData">{{ getFormattedDate(voterInfo.regAddress.beginDate) }}</div>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{$t("Статус_адреса")}}</label>
                                                </v-col>
                                                <v-col  cols="12" sm="12" md="8">
                                                    <div class="onlyReadData">{{ getLocalizedName(voterInfo.regAddress.status) }}</div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="6">
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{$t("Дата_снятия_с_регистрации")}}</label>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="8">
                                                    <div class="onlyReadData">{{ getFormattedDate(voterInfo.regAddress.endDate) }}</div>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{$t("Причина_недействительности")}}</label>
                                                </v-col>
                                                <v-col  cols="12" sm="12" md="8">
                                                    <div class="onlyReadData">{{ getLocalizedName(voterInfo.regAddress.invalidity) }}</div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <!--Обертка для секции-->
                        <v-col 
                            cols="12" 
                            sm="12" 
                            md="12" 
                            class="section-wrapper"
                            v-for="(address, index) in getAsArray(voterInfo.addresses)"
                            :key="index"
                        >
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{$t("Адрес_временной_регистрации")}}</div>
                                    <v-row class="one-column-field-in-one-box" no-gutters>
                                        <v-col cols="12" sm="12" md="2">
                                            <label class="f-label">{{$t("Адрес")}}</label>
                                        </v-col>
                                        <v-col  cols="12" sm="12" md="10">
                                            <div class="onlyReadData">{{ getLocalizedAddress(address) }}</div>
                                            <!--<div class="onlyReadData">{{ getLocalizedName(address.country) }}, {{ getLocalizedName(address.district) }}, {{ getLocalizedName(address.region) }}{{ (address.city ? ', ' : '') }}{{ address.city }}, {{ address.street }}, {{ address.building }}{{ (address.flat ? ',' : '') }} {{ address.flat }}</div>-->
                                        </v-col>
                                    </v-row>
                                    <v-row class="two-columns-field-in-one-box" no-gutters>
                                        <v-col cols="12" sm="12" md="6">
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{$t("Дата_регистрации")}}</label>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="8">
                                                    <div class="onlyReadData">{{ getFormattedDate(address.beginDate) }}</div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="6">
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{$t("Срок_регистрации")}}</label>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="8">
                                                    <div class="onlyReadData">{{ getFormattedDate(address.endDate) }}</div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-tab-item>

                <v-tab-item>
                    <v-row dense>
                        <!--Обертка для секции-->
                        <v-col cols="12" sm="12" md="12">
                            <v-data-table
                                :headers="documentsTableHeaders"
                                :items="documents"
                                :options.sync="optionsBordersTable"
                                :page.sync="optionsBordersTable.page"
                                class="elevation-1 customization-list-table pagination-list-table sticky-table"
                                :items-per-page="25"
                                :footer-props="{ itemsPerPageOptions:[25, 50, 100] }"
                                @page-count="paginationBordersTablePageCount = $event"
                            >

                                <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

                                <template v-slot:[`item.type`]="{ item }">
                                    <div class="etw-text-container">
                                        <div class="etw-text etw-text-first-up" v-tooltip.top-center="getLocalizedName(item.type)">
                                            {{ getLocalizedName(item.type) }}
                                        </div>
                                    </div>
                                </template>

                                <template v-slot:[`item.number`]="{ item }">
                                    <div class="d-flex align-center flex-grow-1">
                                        <v-icon
                                            small
                                            left
                                            :set="documentInfo = calculateDocumentValidity(item)"
                                            :color="documentInfo.color"
                                        >
                                            far fa-address-card
                                        </v-icon>
                                        {{ item.number }}
                                    </div>
                                </template>

                                <template v-slot:[`item.beginDate`]="{ item }">
                                    {{ getFormattedDate(item.beginDate) }}
                                </template>
                                <template v-slot:[`item.endDate`]="{ item }">
                                    {{ getFormattedDate(item.endDate) }}
                                </template>
                                <template v-slot:[`item.issueOrganization`]="{ item }">
                                    <div class="etw-text-container">
                                        <div class="etw-text etw-text-first-up" v-tooltip.top-center="getLocalizedName(item.issueOrganization)">
                                            {{ getLocalizedName(item.issueOrganization) }}
                                        </div>
                                    </div>
                                </template>
                                <template v-slot:[`item.status`]="{ item }">
                                    <div class="etw-text-container">
                                        <div class="etw-text etw-text-first-up" v-tooltip.top-center="getLocalizedName(item.status)">
                                            {{ getLocalizedName(item.status) }}
                                        </div>
                                    </div>
                                </template>
                                <template v-slot:[`item.invalidityDate`]="{ item }">
                                    {{ getFormattedDate(item.invalidityDate) }}
                                </template>

                                <template v-slot:[`footer.prepend`]>
                                    <v-pagination
                                        v-model="optionsBordersTable.page"
                                        :length="paginationBordersTablePageCount"
                                        :total-visible="$listPaginationTotal"
                                        color="teal"
                                    ></v-pagination>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-tab-item>

                <v-tab-item>
                    <v-row dense>
                        <!--Обертка для секции-->
                        <v-col cols="12" sm="12" md="12" class="section-wrapper">
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{$t("Сведения_о_дееспособности")}}</div>
                                    
                                    <v-row class="one-column-field-in-one-box" no-gutters>
                                        <v-col cols="12" sm="12" md="6">
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{$t("Статус_дееспособности")}}</label>
                                                </v-col>
                                                <v-col  cols="12" sm="12" md="8">
                                                    <div class="onlyReadData">{{ getLocalizedName($_.get(voterInfo, 'personCapableStatus.capableStatus')) }}</div>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{$t("Дата_решения_суда")}}</label>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="8">
                                                    <div class="onlyReadData">{{ getFormattedDate($_.get(voterInfo, 'personCapableStatus.capableDate')) }}</div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <!--Обертка для секции-->
                        <v-col cols="12" sm="12" md="12" class="section-wrapper">
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{$t("Сведения_об_исключении_ИИН")}}</div>
                                    
                                    <v-row class="one-column-field-in-one-box" no-gutters>
                                        <v-col cols="12" sm="12" md="6">
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{$t("Признак_исключения")}}</label>
                                                </v-col>
                                                <v-col  cols="12" sm="12" md="8">
                                                    <div class="onlyReadData">{{ getLocalizedName($_.get(voterInfo, 'excludeStatus.excludeReason')) }}</div>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{$t("Дата_исключения")}}</label>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="8">
                                                    <div class="onlyReadData">{{ getFormattedDate($_.get(voterInfo, 'excludeStatus.excludeDate')) }}</div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <!--Обертка для секции-->
                        <v-col cols="12" sm="12" md="12" class="section-wrapper">
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{$t("Статус_содержания_в_местах_лишения_свободы")}}</div>
                                    
                                    <v-row class="one-column-field-in-one-box" no-gutters>
                                        <v-col cols="12" sm="12" md="6">
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{$t("Текущий_статус")}}</label>
                                                </v-col>
                                                <v-col  cols="12" sm="12" md="8">
                                                    <div class="onlyReadData">{{ getLocalizedName($_.get(voterInfo, 'imprisonedStatus.imprisonedStatus')) }}</div>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{$t("Дата_начала_срока")}}</label>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="8">
                                                    <div class="onlyReadData">{{ getFormattedDate($_.get(voterInfo, 'imprisonedStatus.imprisonedBeginDate')) }}</div>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{$t("Дата_окончания_срока")}}</label>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="8">
                                                    <div class="onlyReadData">{{ getFormattedDate($_.get(voterInfo, 'imprisonedStatus.imprisonedEndDate')) }}</div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <!--Обертка для секции-->
                        <v-col cols="12" sm="12" md="12" class="section-wrapper">
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{$t("Данные_свидетельства_о_смерти")}}</div>
                                    
                                    <v-row class="two-columns-field-in-one-box" no-gutters>
                                        <v-col cols="12" sm="12" md="6">
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{$t("Дата_смерти")}}</label>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="8">
                                                    <div class="onlyReadData">{{ getFormattedDate(voterInfo.deathDate) }}</div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="6">
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{$t("Номер_свидетельства")}}</label>
                                                </v-col>
                                                <v-col  cols="12" sm="12" md="8">
                                                    <div class="onlyReadData">{{ getTextValue($_.get(voterInfo, 'deathCertificate.number')) }}</div>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{$t("Дата_выдачи")}}</label>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="8">
                                                    <div class="onlyReadData">{{ getFormattedDate($_.get(voterInfo, 'deathCertificate.beginDate')) }}</div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-tab-item>
                
                <v-tab-item>
                    <v-row dense>

                        <!--Обертка для секции-->
                        <v-col cols="12" sm="12" md="12">
                            <v-data-table
                                :headers="movementsTableHeaders"
                                :items="movementsList"
                                :options.sync="movementsTableOptions"
                                :page.sync="movementsTableOptions.page"
                                class="elevation-1 customization-list-table pagination-list-table sticky-table"
                                :items-per-page="25"
                                :footer-props="{ itemsPerPageOptions:[25, 50, 100] }"
                                @page-count="paginationMovementsTablePageCount = $event"
                            >

                                <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>


                                <template v-slot:[`item.fromDate`]="{ item }">
                                    {{ $system.dateFormat(item.fromDate, 'DD.MM.yyyy') }}
                                </template>

                                <template v-slot:[`item.toDate`]="{ item }">
                                    <template v-if="item.closed">
                                        {{ $system.dateFormat(item.toDate, 'DD.MM.yyyy') }}                                        
                                    </template>
                                    <template v-else>
                                        <div v-tooltip.top-center="$t('Текущее_состояние')">
                                            &infin;
                                        </div>
                                    </template>
                                </template>
                                
                                <template v-slot:[`item.voterAddress`]="{ item }">
                                    <div class="etw-text-container">
                                        <div class="etw-text etw-text-first-up">
                                            {{ item.voterAddress.address }}
                                        </div>
                                    </div>
                                </template>

                                <template v-slot:[`item.electionPartNumber`]="{ item }">
                                    <div v-tooltip.top-center="$t('Текущий_номер_участка')">
                                        {{ item.electionPartNumber }}
                                    </div>
                                </template>
                                
                                <template v-slot:[`footer.prepend`]>
                                    <v-pagination
                                        v-model="movementsTableOptions.page"
                                        :length="paginationMovementsTablePageCount"
                                        :total-visible="$listPaginationTotal"
                                        color="teal"
                                    ></v-pagination>
                                </template>

                            </v-data-table>
                        </v-col>

                    </v-row>
                </v-tab-item>

                <v-tab-item>
                    <v-row dense>
                        <!--Обертка для секции-->
                        <v-col cols="12" sm="12" md="12" class="section-wrapper">
                            <v-card flat>
                                <v-card-text>
                                    <vue-json-pretty
                                        :data="voterInfo"
                                        :showIcon="true"
                                        :showLength="true"
                                        :showLineNumber="true"
                                        :deep="1"
                                    />
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-tab-item>

            </v-tabs-items>

        </v-form>
    </div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

import sys from '@/services/system';
import { httpAPI, errorHandler } from "@/api/httpAPI";

export default {
    name: "Voter",
    components: {
        VueJsonPretty,
    },
    props: {
        id: {
            type: String,
            default: null
        },
        index: {
            type: String,
            default: null
        },
        fromSearchGBDFl: {
            type: Boolean,
            default: false
        },
        temporary: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            tab: null,

            optionsBordersTable: {
                page: 1,
                itemsPerPage : 25,
                sortBy : ['beginDate'],
                sortDesc : [true],
                groupBy : [],
                groupDesc : [],
                multiSort : false,
                mustSort : false 
            },
            paginationBordersTablePageCount: 0,

            electionPart: null,
            voterHistory: null,
            voterInfo: null,
            addressRegistryAddress: null,
            addressRegistryPrimaryRka: null,

            movementsList: [],
            movementsTableOptions: {
                page: 1,
                itemsPerPage : 25,
                sortBy : ['fromDate'],
                sortDesc : [ true ],
                groupBy : [],
                groupDesc : [],
                multiSort : false,
                mustSort : true 
            },
            paginationMovementsTablePageCount: 0,
        }
    },
    computed:{
        documentsTableHeaders() {
            return [
                { text: this.$t("Тип_документа"), value: 'type', width: '200px' },
                { text: this.$t("Номер"), value: 'number', width: '120px' },
                { text: this.$t("Дата_выдачи"), value: 'beginDate', width: '120px' },
                { text: this.$t("Срок_действия"), value: 'endDate', width: '140px' },
                { text: this.$t("Кем_выдано"), value: 'issueOrganization' },
                { text: this.$t("Признак_недействительности"), value: 'status', width: '250px' },
                { text: this.$t("Дата_недействительности"), value: 'invalidityDate', width: '200px' },
            ];
        },
        movementsTableHeaders() {
            return [
                { text: this.$t("С_даты"), value: 'fromDate', width: '100px' },
                { text: this.$t("До_даты"), value: 'toDate', width: '100px', sortable: false },
                { text: this.$t("№_участка"), value: 'electionPartNumber', width: '150px', sortable: false },
                { text: this.$t("Адрес_избирателя"), value: 'voterAddress', sortable: false }
            ];
        },
        documents(){
            if (this.voterInfo.documents == null)
                return [];

            if (Array.isArray(this.voterInfo.documents.document))
                return this.voterInfo.documents.document;

            if (this.voterInfo.documents.document)
                return [this.voterInfo.documents.document];

            return [];
        },
        noGBDFldata() {
            return this.$i18n.t('Сведения_в_ГБД_ФЛ_отсутствуют');
        },
        showJsonDataTab() {
            return this.$store.getters['global/auth/getUserPermissions'].includes("SaylauVoterJsonData");
        }
    },
    methods: {
        async onVoterMovementsClicked() {
            this.movementsList = (await httpAPI.get(`saylau/voters/${this.voterInfo.iin}/movements`))?.data ?? [];
        },
        handle(e) {
            this.dateMenuVisible = false
            this.$emit('input', e);
        },
        getLocalizedName(value, gbdfl = true) {
            if (!value) {
                if (gbdfl)
                    return this.noGBDFldata;
                else 
                    return this.$i18n.t('Нет_данных')
            }

            return this.$i18n.locale == 'kk' 
                ? value.nameKz
                : value.nameRu;
        },
        getLocalizedAddress(value) {
            if (!value) return this.noGBDFldata;

            let addressItems = [];

            if (value.country) addressItems.push(this.getLocalizedName(value.country));
            if (value.district) addressItems.push(this.getLocalizedName(value.district));
            if (value.region) addressItems.push(this.getLocalizedName(value.region));
            if (value.city) addressItems.push(value.city);
            if (value.street) addressItems.push(value.street);
            if (value.building) addressItems.push(value.building);
            if (value.corpus) addressItems.push(value.corpus);
            if (value.flat) addressItems.push(value.flat);

            return addressItems.join(", ");
        },
        getDocumentTitle(document) {
            switch(document.type.code)
            {
                case '002': return this.$i18n.t('Удостоверение_личности');
                case '001': return this.$i18n.t('Паспорт');
            }            
        },
        getFormattedDate(value){
            if (!(value))
                return this.noGBDFldata;

            return sys.dateFormat(value, 'DD.MM.YYYY') ?? "";
        },
        getTextValue(value){
            if (!(value))
                return this.noGBDFldata;

            return value;
        },
        getAsArray(value)
        {
            if (value == null || value == '')
                return [false];

            if (value.address){
                if (Array.isArray(value.address))
                    return value.address;
                return [value.address];
            }
            return [false];
        },
        calculateDocumentValidity (document) {
            if (document) {
                let now = this.$moment();
                let endDate = this.$moment(document.endDate);
                let dateValid = endDate >= now;

                let status = document?.status?.code ?? null;
                let statusValid = status === null || status === '00';

                let isValid = !Object.prototype.hasOwnProperty.call(document, 'invalidityDate');

                if (dateValid && statusValid && isValid)
                    return { color: "green" };
            }

            return { color: "red" };
        },
        Export() {
            let url = '';

            if (this.fromSearchGBDFl)
                url =`saylau/gbdfl-search/${this.id}/${this.index}?export=true`;
            else
                url = `saylau/voters/${this.id}?tmp=${this.temporary === true}&export=true`;

            let onResolve = (response) => {
                let filename = "Export.xlsx";
                let headerLine = response.headers['content-disposition'];

                if (headerLine) {
                    let token = headerLine.split(';').map(t => t.trim()).find(t => t.startsWith("filename*=UTF-8"));

                    if (token)
                        filename = decodeURIComponent(token.replace("filename*=UTF-8''", ""));
                }

                this.$notify.success(this.$i18n.t("Загрузка_файла_filename_завершена.message", { filename: filename }));
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            };

            let onReject = (error) => {
                errorHandler(error);
            };

            let request = httpAPI.get(url, { responseType: 'blob', skipErrorHandler: true });
            this.$notify.async(request, onResolve, onReject, this.$t("Подготовка_данных"), { icons: { enabled: true } });
        }
    },
    filters: {
        formattedDate: function (value) {
            return sys.dateFormat(value, 'DD.MM.YYYY') ?? "";
        }
    },
    async beforeRouteEnter(to, from, next) {
        
        try
        {
            let url = '';

            if (to.params.fromSearchGBDFl)
                url =`saylau/gbdfl-search/${to.params.id}/${to.params.index}`;
            else
                url = `saylau/voters/${to.params.id}?tmp=${to.params.temporary === true}`;

            let response = await httpAPI.get(url);

            if (response) {
                next(vm =>
                {
                    vm.voterInfo = JSON.parse(response.data.jsonData);
                    vm.electionPart = response.data.electionPart;
                    vm.voterHistory = response.data.history;
                    vm.addressRegistryAddress = response.data.addressRegistryAddress;
                    vm.addressRegistryPrimaryRka = response.data.addressRegistryPrimaryRka;
                });
            }
            else
                return;
        } 
        catch (error)
        {
            console.log(error);
        }
    }, 
    async beforeRouteUpdate(to, from, next) {
        try
        {
            let url = '';

            if (to.params.fromSearchGBDFl)
                url =`saylau/gbdfl-search/${to.params.id}/${to.params.index}`;
            else
                url = `saylau/voters/${to.params.id}?tmp=${to.params.temporary === true}`;

            let response = await httpAPI.get(url);
            
            if (response) {
                this.voterInfo = JSON.parse(response.data.jsonData);
                this.electionPart = response.data.electionPart;
                this.voterHistory = response.data.history;
                this.addressRegistryAddress = response.data.addressRegistryAddress;
                this.addressRegistryPrimaryRka = response.data.addressRegistryPrimaryRka;
                next();
            }
            else
                return;
        } 
        catch (error)
        {
            console.log(error);
        }
    }
}
</script>